import * as React from 'react'
import { Dispatcher } from '../../add-state'
import { Trans } from '@lingui/macro'
import classnames from 'classnames'
import css from './styles.css'
import { Sliders } from '../../../lib/components/icon'

type FilterButtonProps = {
    loading: boolean
    dispatch: Dispatcher<{
        type: 'advancedFilterSelected'
        advancedFilterSelected: { [key: string]: boolean }
        advancedFilterShowState: {
            desktop: boolean | null
            mobile: boolean
        }
    }>
    advancedFilterSelected: { [key: string]: boolean }
    advancedFilterShowState: {
        desktop: boolean | null
        mobile: boolean
    }
}

export function FilterButton(props: FilterButtonProps): React.ReactElement {
    const { loading, dispatch, advancedFilterSelected, advancedFilterShowState } = props

    function clickHandler() {
        dispatch({
            type: 'advancedFilterSelected',
            advancedFilterSelected,
            advancedFilterShowState: {
                desktop: !advancedFilterShowState.desktop,
                mobile: advancedFilterShowState.mobile,
            },
        })
    }

    return (
        <div
            className={classnames(
                advancedFilterShowState.desktop ? css.filterButtonContainer : css.filterButtonContainerWhite,
            )}
        >
            <button
                onClick={clickHandler}
                disabled={loading}
                aria-expanded={advancedFilterShowState.desktop ? advancedFilterShowState.desktop : false}
            >
                <Trans>Search & Filters</Trans>
                <Sliders aria-hidden='true' />
            </button>
        </div>
    )
}
