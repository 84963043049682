import * as React from 'react'
import { t } from '@lingui/macro'

import { AlignJustify, ThLarge, ThList } from '@discogs/amped/icons'

import { useLocalisation } from '../../i18n'

import css from './styles.css'

export type ViewSetting = 'textWithCovers' | 'coversOnly' | 'textOnly'

type ViewProps = {
    setView: (value: ViewSetting) => void
    view?: ViewSetting
}

export function View(props: ViewProps): React.ReactElement {
    const { setView, view = 'textWithCovers' } = props
    const { i18n } = useLocalisation()

    return (
        <div className={css.views} role='radiogroup'>
            <button
                type='button'
                role='radio'
                aria-checked={view === 'coversOnly'}
                onClick={() => setView('coversOnly')}
                title={i18n._(`Covers-only view`)}
            >
                <ThLarge aria-hidden='true' role='img' />
            </button>
            <button
                type='button'
                role='radio'
                aria-checked={view === 'textWithCovers'}
                onClick={() => setView('textWithCovers')}
                title={i18n._(`Text with covers view`)}
            >
                <ThList aria-hidden='true' role='img' />
            </button>
            <button
                type='button'
                role='radio'
                aria-checked={view === 'textOnly'}
                onClick={() => setView('textOnly')}
                title={i18n._(t`Text-only view`)}
            >
                <AlignJustify aria-hidden='true' role='img' />
            </button>
        </div>
    )
}
