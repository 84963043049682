// https://discogsinc.atlassian.net/browse/CTLG-2500
/* eslint-disable */
export function removeTypename(value: any): any {
    if (Array.isArray(value)) {
        return value.map(removeTypename)
    } else if (value !== null && typeof value === 'object') {
        const { __typename, ...rest } = value
        for (const key in rest) {
            rest[key] = removeTypename(rest[key])
        }
        return rest
    }
    return value
}
