import { PersistedReleaseTypeTotals } from '.'
import { ArtistCreditFacets, ArtistFacetConnection } from '../../api/types'
import { FacetObjects } from '../../lib/components/facetFilters'

export type Facet = {
    totalCount: number
    superFacetName: string
    facetDetails: {
        totalCount: number
        facetName: string
        subFacetDetails: { totalCount: number; subFacetName: string }[]
    }[]
}

export type Facets = Facet[]

export type FacetsReturnType = {
    releaseTypes: FacetObjects
    credits: FacetObjects
    year: FacetObjects
    genre: FacetObjects
    format: FacetObjects
    label: FacetObjects
    country: FacetObjects
}

export type ReleaseTypeTotalsReturnType = {
    [key: number]: ArtistFacetConnection
}
export type SuperFacetTotalsReturnType = {
    [key: string]: ArtistFacetConnection[]
}

export type ReleaseTypes = {
    facetDetails: ArtistFacetConnection[]
    superFacetName: string
    totalCount: number
}[]

export function getReleaseTypeTotals(releaseTypes: ReleaseTypes): ReleaseTypeTotalsReturnType {
    const facetTotals = releaseTypes.filter(
        (facet: { superFacetName: string }) => facet.superFacetName === 'release_type',
    )

    return facetTotals[0].facetDetails
}

export function getSuperFacetTotals(releaseTypes: ReleaseTypes): SuperFacetTotalsReturnType {
    const handleNoSearchResults = {
        Releases: [],
        Appearances: [],
        Unofficial: [],
        Credits: [],
    }

    const creditCatergory = releaseTypes.filter(
        (facet: { superFacetName: string }) => facet.superFacetName === 'credit_category',
    )

    if (creditCatergory.length < 1) {
        return handleNoSearchResults
    }

    const superFacetTotals = {
        Releases: creditCatergory[0].facetDetails.filter(
            (facet: { facetName: string }) => facet.facetName === 'Releases',
        ),
        Appearances: creditCatergory[0].facetDetails.filter(
            (facet: { facetName: string }) => facet.facetName === 'Appearances',
        ),
        Unofficial: creditCatergory[0].facetDetails.filter(
            (facet: { facetName: string }) => facet.facetName === 'Unofficial',
        ),
        Credits: creditCatergory[0].facetDetails.filter(
            (facet: { facetName: string }) => facet.facetName === 'Credits',
        ),
    }

    return superFacetTotals
}

export type GetCountsParams = {
    creditCategoryTotals: ArtistCreditFacets['facets']
    filterSelected: string[]
    initialFilter: string[]
    creditName: string
    persistedReleaseTypeTotals: PersistedReleaseTypeTotals
    searchResultsTotals: SuperFacetTotalsReturnType | null
    search: string
    dynamicReleaseTypeTotalCount: number | undefined
    advancedFilterSelected: { [key: string]: boolean }
}

export function getCounts(getCountsParams: GetCountsParams): number | null {
    const {
        creditCategoryTotals,
        filterSelected,
        initialFilter,
        creditName,
        persistedReleaseTypeTotals,
        searchResultsTotals,
        search,
        dynamicReleaseTypeTotalCount,
        advancedFilterSelected,
    } = getCountsParams

    if (creditCategoryTotals === undefined || dynamicReleaseTypeTotalCount === undefined) {
        return null
    }

    // has the user selected an advancedFilter?

    function advancedFilterApplied(advancedFilterSelected: GetCountsParams['advancedFilterSelected']) {
        if (Object.keys(advancedFilterSelected).length === 0) {
            return true
        }

        for (const value of Object.values(advancedFilterSelected)) {
            if (value) {
                return false
            }
        }

        return true
    }

    const advancedFilterTotalCount = advancedFilterApplied(advancedFilterSelected) ? 0 : dynamicReleaseTypeTotalCount

    const { credits } = getFacets(creditCategoryTotals as unknown as Facets)
    const creditCategory = filterSelected[0] ? filterSelected[0] : initialFilter[0]

    // has the user selected a credit subFacet?
    const creditCount = creditName
        ? credits[filterSelected[0]].subFacet.filter(
              (credit: { subFacetName: string }) => credit.subFacetName === creditName,
          )[0].totalCount
        : null

    // has the user selected a releaseType or a superFacet
    const releaseTypeCheck = filterSelected.length > 1 ? filterSelected[1] : creditCategory

    const releaseTypeCount =
        filterSelected.length > 1
            ? persistedReleaseTypeTotals[creditCategory as keyof typeof persistedReleaseTypeTotals].filter(
                  (releaseType) => releaseType.facetName === releaseTypeCheck,
              )[0].totalCount
            : credits[creditCategory].totalCount

    // has the user entered a search term

    const searchTermCount = searchResultsTotals?.[creditCategory]?.[0]?.totalCount ?? 0

    const totalCount =
        advancedFilterTotalCount > 0
            ? advancedFilterTotalCount
            : creditCount
            ? creditCount
            : search
            ? searchTermCount
            : releaseTypeCount

    return totalCount
}

const keys = {
    release_type: 'releaseTypes',
    credit_category: 'credits',
    format: 'format',
    label: 'label',
    year: 'year',
    country: 'country',
    genre: 'genre',
} as const

export function getFacets(artistFacets: Facets): FacetsReturnType {
    const result: FacetsReturnType = {
        releaseTypes: {},
        credits: {},
        format: {},
        label: {},
        year: {},
        genre: {},
        country: {},
    }

    for (const facet of artistFacets) {
        const name = facet.superFacetName
        if (name in keys) {
            const key = keys[name as keyof typeof keys]

            for (const facetDetails of facet.facetDetails) {
                result[key][facetDetails.facetName] = {
                    subFacet: facetDetails.subFacetDetails,
                    totalCount: facetDetails.totalCount,
                }
            }
        }
    }

    return result
}
