import * as React from 'react'

import { ItemType, LabelReleaseInfoFragment, ReleaseCardFragment } from '../../api/types'
import { Link } from '../../lib/components/link'
import { useId } from '../../lib/use-id'
import { collateArtists, artistName } from '../../lib/collate-artists'
import { Skittle } from '../../lib/components/skittle'
import { ReleaseArtistLink } from '../release-artist-link'
import { ReleaseThumbnail } from '../release-thumbnail'
import { useLogin } from '../../lib/login'
import { explicitReleaseContent } from '../../lib/explicit-contentIds'

import css from './styles.css'
import { NSFWImagePlaceholderSmall } from '../../lib/components/nsfw-image-placeholder'

type Props = Partial<ReleaseCardFragment> &
    Partial<LabelReleaseInfoFragment> & {
        lazyImage?: boolean
        released?: string
        collectionItems?: {
            totalCount: number
        }
        myListings?: {
            totalCount: number
        }
        versionCount: number | undefined
        itemType?: ItemType
        discogsId?: number
    }

export function ReleaseCard(props: Props): React.ReactElement {
    const {
        images,
        primaryArtists,
        title,
        formats,
        siteUrl,
        lazyImage,
        released,
        collectionItems,
        inWantlist,
        myListings,
        versionCount,
        masterRelease,
        itemType,
        discogsId,
    } = props

    const { loggedIn } = useLogin()

    const isNotMaster = versionCount && versionCount < 2

    const format = formats?.[0]?.name ?? 'Vinyl'

    const imgObj =
        itemType === 'LABEL' && masterRelease?.keyRelease.images.edges[0]?.node
            ? masterRelease.keyRelease.images.edges[0]?.node
            : images?.edges[0]?.node

    const image = imgObj?.thumbnail
    const isNSFWImage = imgObj?.nsfw

    const id = useId()

    const alt = `${title} - ${primaryArtists ? collateArtists(primaryArtists, artistName).join('') : ''}`

    const blockForLoggedInUsers = explicitReleaseContent.includes(discogsId ?? 0)

    function coverImage(): React.ReactElement {
        if ((isNSFWImage && !loggedIn) || blockForLoggedInUsers) {
            return (
                <div className={css.nsfwImageContainer}>
                    <NSFWImagePlaceholderSmall showButton={false} />
                </div>
            )
        }

        return <ReleaseThumbnail image={image} alt={alt} format={format} lazy={lazyImage} discogsId={discogsId ?? 0} />
    }

    return (
        <figure className={css.card}>
            <Link href={siteUrl} className={css.image}>
                {coverImage()}
            </Link>
            <figcaption>
                <div className={css.title}>
                    <Link href={siteUrl} title={title} id={id}>
                        {title}
                    </Link>
                </div>
                <div className={css.artists}>
                    <ReleaseArtistLink artists={primaryArtists ?? []} />
                </div>
                {released && <div className={css.year}>{released.substring(0, 4)}</div>}
            </figcaption>
            <div className={css.skittlesOverlay}>
                <span className={css.skittles}>
                    <Skittle
                        type='collection'
                        count={
                            isNotMaster ? collectionItems?.totalCount ?? 0 : masterRelease?.inUserCollectionCount ?? 0
                        }
                    />
                    <Skittle
                        type='wantlist'
                        count={isNotMaster ? (inWantlist ? 1 : 0) : masterRelease?.inUserWantlistCount ?? 0}
                    />
                    <Skittle
                        type='inventory'
                        count={isNotMaster ? myListings?.totalCount ?? 0 : masterRelease?.myListings?.totalCount ?? 0}
                    />
                </span>
            </div>
        </figure>
    )
}
