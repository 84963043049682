/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { ApolloCache, FetchResult } from '@apollo/client'
import { t } from '@lingui/macro'

import { useLogin } from '../lib/login'
import { useErrorHandler } from '../lib/components/errors'
import {
    CollectionItemConnection,
    useRemoveReleaseFromCollectionMutation as useRemoveReleaseFromCollectionMutation_,
    RemoveReleaseFromCollectionMutation,
    ReleaseMasterFragmentDoc,
    ReleaseMasterFragment,
} from '../api/types'
import { Modifier } from '@apollo/client/cache'

type Params = {
    discogsId: number
    itemId: number
}

type Mutation = {
    called: boolean
    loading: boolean
    perform: (params: Params) => Promise<void>
}

export function useRemoveReleaseFromCollectionMutation(): Mutation {
    const { user } = useLogin()
    const onError = useErrorHandler(t`Could not remove the release from your collection`)
    const [remove, info] = useRemoveReleaseFromCollectionMutation_()

    async function perform(params: Params): Promise<void> {
        const { discogsId, itemId } = params
        if (!user) {
            return
        }

        await remove({
            variables: {
                input: {
                    discogsId: itemId,
                },
            },
            optimisticResponse: {
                removeFromCollection: {
                    // @ts-expect-error: typenames are not generated
                    __typename: 'RemoveCollectionItemPayload',
                    success: true,
                },
            },
            update(
                cache: ApolloCache<RemoveReleaseFromCollectionMutation>,
                result: FetchResult<RemoveReleaseFromCollectionMutation>,
            ): void {
                const id = cache.identify({ __typename: 'Release', discogsId })
                const res = cache.readFragment({
                    id,
                    fragment: ReleaseMasterFragmentDoc,
                }) as ReleaseMasterFragment | undefined

                cache.modify({
                    id,
                    fields: {
                        collectionItems: ((
                            existing: CollectionItemConnection | undefined,
                        ): CollectionItemConnection | undefined => {
                            if (!existing || !result.data) {
                                return existing
                            }

                            const id = cache.identify({ __typename: 'CollectionItem', discogsId: itemId })
                            return {
                                ...existing,
                                totalCount: existing.totalCount - 1,
                                // @ts-expect-error
                                edges: (existing.edges ?? []).filter((edge) => edge.node?.__ref !== id),
                            }
                            // Apollo Client (<= 3.x) has strict Modifier type requirements that
                            // can be difficult to satisfy when working with complex nested types.
                            // This type assertion works around those type mismatches.
                            // TODO: Consider refactoring to use a helper function for better type safety.
                            // https://discogsinc.atlassian.net/browse/RALM-2748
                        }) as unknown as Modifier<null>,
                    },
                })

                if (res?.masterRelease) {
                    cache.modify({
                        id: cache.identify({ __typename: 'MasterRelease', discogsId: res.masterRelease.discogsId }),
                        fields: {
                            inUserCollectionCount(existing: number | undefined): number {
                                return Math.max(0, (existing ?? 0) - 1)
                            },
                        },
                    })
                }
            },
        }).catch(onError)
    }

    return {
        ...info,
        perform,
    }
}
