import { Dispatcher } from '../../add-state'

type Actions = { type: 'pagination'; first: number; last: number; startCursor: string; endCursor: string }

// this util file handles the setting of cursor based pagination in session
// this allows the user to navigate away from a page, return to it (if in the same tab) and
// have their place in the pagination persist.

export function safeGetItem(key: string): string | null {
    if (typeof window !== 'undefined') {
        return sessionStorage.getItem(key)
    }
    return null
}

export function safeSetItem(key: string, value: string): void {
    if (typeof window !== 'undefined') {
        sessionStorage.setItem(key, value)
    }
}

export function safeRemoveItem(key: string): void {
    if (typeof window !== 'undefined') {
        sessionStorage.removeItem(key)
    }
}

export function handlePageChange(
    newPageNumber: number,
    currentPageNumber: number,
    endCursor: string,
    startCursor: string,
    perPage: number,
    dispatch: Dispatcher<Actions>,
    page: string,
): void {
    if (newPageNumber > currentPageNumber) {
        safeSetItem('firstItemCount', String(perPage))
        safeSetItem('lastItemCount', '0')
        safeSetItem('endCursor', endCursor)
        safeSetItem('startCursor', '')
        dispatch({
            type: 'pagination',
            first: perPage,
            last: 0,
            startCursor: '',
            endCursor,
        })
    } else {
        safeSetItem('firstItemCount', '0')
        safeSetItem('lastItemCount', String(perPage))
        safeSetItem('startCursor', startCursor)
        safeSetItem('endCursor', '')
        dispatch({
            type: 'pagination',
            first: 0,
            last: perPage,
            startCursor,
            endCursor: '',
        })
    }

    safeSetItem(page, String(newPageNumber))
}

// on pages with this url structure /user/${userName}/lists, we need to clear
// pagination info stored in session when the user navigates in the same
// same tab to another users list of lists. If we don't, the session state
// will be applied to that users list of lists data

export function changeUserSession(username: string, page: string): void {
    const previousUsername = safeGetItem('currentUsername')

    if (previousUsername && previousUsername !== username) {
        // Clear relevant session storage items if username has changed
        safeSetItem(page, '1')
        safeRemoveItem('startCursor')
        safeRemoveItem('endCursor')
        safeRemoveItem('firstItemCount')
        safeRemoveItem('lastItemCount')
    }
    safeSetItem('currentUsername', username)
}
