import React from 'react'

export function useSticky(ref: React.RefObject<HTMLElement>, refreshNeeded = false): boolean {
    const [isSticky, setIsSticky] = React.useState(false)
    const [componentTop, setComponentTop] = React.useState(0)

    React.useEffect(
        function () {
            const componentRef = ref.current
            if (!componentRef) {
                return
            }

            function handleScroll() {
                const currentScroll = window.scrollY
                setIsSticky(currentScroll >= componentTop)
            }

            function updateComponentTop() {
                if (!componentRef) {
                    return
                }
                setComponentTop(componentRef.offsetTop)
            }

            updateComponentTop()

            window.addEventListener('scroll', handleScroll)

            function cleanupEffect() {
                window.removeEventListener('scroll', handleScroll)
            }
            /* eslint-disable-next-line consistent-return */
            return cleanupEffect
        },
        [ref, componentTop, refreshNeeded],
    )

    return isSticky
}
