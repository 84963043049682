import React from 'react'
import { LabelRelationship } from '../../../api/types'
import { intersperse } from '../../intersperse'

type CatalogNumbersProps = {
    catalogNumbers?: LabelRelationship[] | string
    hasVersions: boolean | 0 | undefined
}

// this lib component currently lives in the following places
// Artist Discography
// Label Discography

export function CatalogNumbers(props: CatalogNumbersProps): React.ReactElement | null {
    const { catalogNumbers, hasVersions } = props

    if (!catalogNumbers) {
        return null
    }

    // Label Catalog numbers are found in the API under 'catalogNumberDisplayed'
    // this is because, when viewing a label's discography, the page should only
    // display catalog numbers that are directly attached to the specific label,
    // excluding any numbers pertaining to series or other entities.

    if (typeof catalogNumbers === 'string') {
        return <div>{catalogNumbers}</div>
    }

    // Do not display catalog numbers on artist pages where the release has versions
    if (!hasVersions) {
        return null
    }

    // on Artist pages, unlike label pages we display multiple comma seperated catalog numbers
    const numbers = catalogNumbers
        .map(function (label): string | null {
            const { catalogNumber } = label

            if (!catalogNumber) {
                return null
            }

            return catalogNumber
        })
        .filter((x: string | null, i: number, a: (string | null)[]): boolean => x !== null && a.indexOf(x) === i)

    return <div tabIndex={0}>{intersperse(', ', numbers)}</div>
}
