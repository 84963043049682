import { useHistory } from 'react-router'
import { Location } from 'history'

type SingleParam = string | number | null | undefined
type Param = string | number | (string | number)[] | null | undefined

type Params = {
    [name: string]: Param
}

export function set(location: Location, params: Params): Location {
    const query = new URLSearchParams(location.search)
    for (const key in params) {
        const value = params[key]
        if (value === undefined || value === null) {
            query.delete(key)
            continue
        }

        if (Array.isArray(value)) {
            query.delete(key)
            for (const el of value) {
                query.append(key, el.toString())
            }
            continue
        }

        query.set(key, value.toString())
    }

    return {
        ...location,
        search: stringify(query),
    }
}

export function stringify(params: URLSearchParams): string {
    const q = params.toString()
    if (q.length === 0) {
        return ''
    }

    return `?${q}`
}

type Setter<T> = (value: T, replace?: boolean) => void

export function useQueryParams(name: string): [string | string[] | null, Setter<Param>] {
    const history = useHistory()

    const value = item(new URLSearchParams(history.location.search).getAll(name))

    function setValue(value: Param, replace: boolean = false): void {
        const updated = set(history.location, {
            [name]: value,
        })

        if (replace) {
            history.replace(updated)
        } else {
            history.push(updated)
        }
    }

    return [value, setValue]
}

export function useQueryParam(name: string): [string | null, Setter<SingleParam>] {
    const [value, setValue] = useQueryParams(name)

    if (Array.isArray(value)) {
        return [value[0], setValue]
    }

    return [value, setValue]
}

function item<T>(x: T | T[]): T | T[] | null {
    if (Array.isArray(x)) {
        if (x.length === 0) {
            return null
        }

        return x[0]
    }

    return x
}
