type ParamsReturnObject = {
    yearParams: number[]
    countryParams: string[]
    labelParam: string[]
    formatParam: string[]
    genreParam: string[]
    roleParam: string[]
    pageParam: string[]
    allParamsFromUrl: URLParamsObject
}

type URLParamsObject = {
    [key: string]: boolean
}

export function safeGetUrlParams(): ParamsReturnObject {
    const defaultParams = {
        yearParams: [],
        countryParams: [],
        labelParam: [],
        formatParam: [],
        genreParam: [],
        roleParam: [],
        pageParam: [],
        allParamsFromUrl: {},
    }

    if (typeof window !== 'undefined') {
        const filterParams = new URLSearchParams(window.location.search)
        return {
            yearParams: filterParams.getAll('year').map(Number),
            countryParams: filterParams.getAll('country'),
            labelParam: filterParams.getAll('label'),
            formatParam: filterParams.getAll('format'),
            genreParam: filterParams.getAll('genre'),
            roleParam: filterParams.getAll('role'),
            pageParam: filterParams.getAll('page'),
            allParamsFromUrl: [
                ...filterParams.getAll('year').map(Number),
                ...filterParams.getAll('country'),
                ...filterParams.getAll('label'),
                ...filterParams.getAll('format'),
                ...filterParams.getAll('genre'),
                ...filterParams.getAll('role'),
                ...filterParams.getAll('page'),
            ].reduce<URLParamsObject>(function (acc, param) {
                acc[param] = true
                return acc
            }, {}),
        }
    }

    return defaultParams
}
