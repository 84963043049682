import * as React from 'react'

import { Link } from '../link'
import { intersperse } from '../../intersperse'
import { ShowMore } from '../show-more'
import { ExternalLink } from '../icon'

import css from './styles.css'

type Props = {
    urls?: string[] | undefined | null
    setIsHeaderExpanded?: (key: boolean) => void
}

const truncateAt = 40

const sites: Record<string, string> = {
    'allmusic.com': 'All Music',
    'archive.com': 'Archive.org',
    'bandcamp.com': 'Bandcamp',
    'beatport.com': 'Beatport',
    'bsky.app': 'Bluesky',
    'discogs.com': 'Discogs',
    'facebook.com': 'Facebook',
    'flickr.com': 'Flickr',
    'google.com': 'Google',
    'youtube.com': 'YouTube',
    'imdb.com': 'Imdb',
    'instagram.com': 'Instagram',
    'last.fm': 'Lastfm',
    'linkedin.com': 'LinkedIn',
    'linktr.ee': 'Linktree',
    'mastodon.social': 'Mastadon',
    'mixcloud.com': 'Mixcloud',
    'myspace.com': 'MySpace',
    'pinterest.com': 'Pinterest',
    'pitchfork.com': 'Pitchfork',
    'ra.co': 'RA',
    'residentadvisor.com': 'Resident Advisor',
    'reverbnation.com': 'Reverb Nation',
    'songkick.com': 'Songkick',
    'soundcloud.com': 'Soundcloud',
    'tiktok.com': 'Tiktok',
    'tumblr.com': 'Tumblr',
    'twitch.com': 'Twitch',
    'twitter.com': 'X',
    'vimeo.com': 'Vimeo',
    'whatgear.com': 'What Gear',
    'wikipedia.org': 'Wikipedia',
    'whosampled.com': 'Who Sampled',
}

function hostname(url: string): string {
    try {
        return new URL(url).hostname.replace(/^www\./i, '').toLowerCase()
    } catch (err) {
        return url
    }
}

function cleanUrlsStepOne(inputArray: string[]): string[] {
    const validUrls: string[] = []

    for (const item of inputArray) {
        const words = item.split(' ')

        const validWords = words.filter((word) => word.startsWith('http://') || word.startsWith('https://'))

        if (validWords.length === 1) {
            validUrls.push(validWords[0])
        }
    }

    return validUrls
}

function cleanUrlsStepTwo(urls: string[]): { href: string; name: string }[] {
    const cleaned = cleanUrlsStepOne(urls)
    return cleaned.map(function (url) {
        const tld = hostname(url)

        for (const pattern in sites) {
            if (tld.endsWith(pattern)) {
                return { href: url, name: sites[pattern] }
            }
        }

        return { href: url, name: tld }
    })
}

export function Sites(props: Props): React.ReactElement | null {
    const { urls, setIsHeaderExpanded } = props

    const [shouldShowMore, setShouldShowMore] = React.useState(false)

    if (!urls) {
        return null
    }

    const truncate = shouldShowMore ? urls.length : truncateAt
    const truncated = truncate < urls.length

    const sites = cleanUrlsStepTwo(urls)

    return (
        <>
            {intersperse(
                ', ',
                sites.slice(0, truncate).map(function (site, idx) {
                    return (
                        <Link
                            href={site.href}
                            key={site.href}
                            target='_blank'
                            rel='noreferrer noopener nofollow'
                            className={css.link}
                        >
                            {site.name}{' '}
                            <ExternalLink className={css.external} aria-hidden='true' style={{ width: '.7rem' }} />
                        </Link>
                    )
                }),
            )}
            {!shouldShowMore && truncated && ', ...'}
            <br />
            {urls.length > truncateAt && (
                <ShowMore
                    isShowingMore={shouldShowMore}
                    onToggle={setShouldShowMore}
                    setIsHeaderExpanded={setIsHeaderExpanded}
                />
            )}
        </>
    )
}
