import * as React from 'react'
import classnames from 'classnames'
import { Search } from '../../../lib/components/icon'
import { useHistory, useLocation } from 'react-router'
import { set, useQueryParams } from '../../../lib/query'
import { Dispatcher } from '../../add-state'
import { Close } from '@discogs/amped/icons'

import css from './style.css'

type SearchBarProps = {
    dispatch: Dispatcher<{ type: 'search'; search: string }>
    mobile?: boolean
    search: string | null
    loading: boolean
    placeholder: string
    ariaLabel: string
    showClearButton?: boolean
    className?: string
}

type SearchBarInputProps = SearchBarProps & React.InputHTMLAttributes<HTMLInputElement>

// This re-usable component takes a dispatch like this
// dispatch({ type: 'search', search: searchParam })
// and a string (the search term)
// it is currently on the Label page, the Artist page & the collection page.

// On those pages its show/hide state is triggered by a filters button
// this button can be found in the filterButton folder in lib

export function SearchBar(props: SearchBarInputProps): React.ReactElement {
    const { dispatch, search, loading, placeholder, ariaLabel, className = '', showClearButton = false } = props

    const inputRef = React.useRef<HTMLInputElement>(null)
    const history = useHistory()
    const location = useLocation()
    const [superFilter] = useQueryParams('superFilter')
    const [creditFilter] = useQueryParams('creditFilter')
    const [subFilter] = useQueryParams('subFilter')
    const [searchParam] = useQueryParams('searchParam')

    const [inputValue, setInputValue] = React.useState(searchParam ?? '')

    React.useEffect(
        function () {
            setInputValue(searchParam ?? '')
        },
        [searchParam],
    )

    function handleSearchChange(evt: React.ChangeEvent<HTMLInputElement>) {
        const { value } = evt.target
        setInputValue(value)

        if (value === '') {
            if (value === search) {
                return
            }
            dispatch({ type: 'search', search: '' })
            history.push(
                set(location, {
                    superFilter,
                    subFilter,
                    creditFilter,
                    searchParam: undefined,
                }),
            )
        }
    }

    function handleSearchClear(evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        evt.preventDefault()
        setInputValue('')

        if (!searchParam) {
            return
        }

        dispatch({ type: 'search', search: '' })
        history.push(
            set(location, {
                superFilter,
                subFilter,
                creditFilter,
                searchParam: null,
            }),
        )
    }

    function handleSubmit(evt: React.FormEvent<HTMLFormElement>, search: string | null): void {
        evt.preventDefault()
        // node linter is confusing this TS declaration as an experimental node feature.
        // eslint-disable-next-line n/no-unsupported-features/node-builtins
        const formData = new FormData(evt.currentTarget)
        const searchValue = formData.get('name') as string
        if (search === searchValue) {
            return
        }
        dispatch({ type: 'search', search: searchValue })

        history.push(
            set(location, {
                superFilter,
                subFilter,
                creditFilter,
                searchParam: inputValue,
            }),
        )
        if (inputRef.current) {
            inputRef.current.blur()
        }
    }

    return (
        <div className={classnames(css.searchBarContainer, className, loading && css.loading)}>
            <form onSubmit={(evt) => handleSubmit(evt, search)}>
                <label>
                    <input
                        aria-label={ariaLabel}
                        type='text'
                        placeholder={placeholder}
                        name='name'
                        value={inputValue}
                        onChange={handleSearchChange}
                        ref={inputRef}
                    />
                </label>
                <div className={css.formActionButtons}>
                    <button type='submit' aria-label='search'>
                        <Search aria-hidden='true' />
                    </button>
                    {showClearButton && inputValue && (
                        <button aria-label='clear' className={css.clearBtn} onClick={handleSearchClear}>
                            <Close />
                        </button>
                    )}
                </div>
            </form>
        </div>
    )
}
